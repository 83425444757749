import React from 'react'
import ReactDOM from 'react-dom/client'

import { ChakraProvider } from '@chakra-ui/react'

import { AppRouter } from './app/AppRouter'

import { theme } from './app/config/theme'

const container = document.getElementById('root')

if (!container) throw new Error('Failed to find the root element')

const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AppRouter />
    </ChakraProvider>
  </React.StrictMode>,
)
