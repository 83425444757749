import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Container, Box, Text, Button, VStack, Image } from '@chakra-ui/react'

import { Loading, Logo } from 'components'
import { fetchPaymentInfo } from 'lib/client'
import { PaymentInfo } from 'types'

export const PaymentResultScreen: React.FC = () => {
  const navigate = useNavigate()
  const { uuid } = useParams()
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>()

  if (!uuid) {
    throw new Error('Missing uuid')
  }

  useEffect(() => {
    fetchPaymentInfo(uuid).then((r) => setPaymentInfo(r))
  }, [uuid])

  const getResultBody = () => {
    if (!paymentInfo) {
      return (
        <VStack spacing={10} align="center">
          <Loading title={'Retrieving payment result'} />
        </VStack>
      )
    }

    return (
      <>
        <VStack spacing={6} align="center">
          <Logo mx="auto" w="24" />
          <Image
            src={paymentInfo.result?.outcome === 'APPROVED' ? '/Success-Circle-24px.png' : '/Close-Circle-24px.png'}
          />
          <Text fontSize={24} fontWeight={'bolder'}>
            {paymentInfo.result?.outcome === 'APPROVED' ? 'Payment approved' : 'Payment declined'}
          </Text>
        </VStack>
        <VStack spacing={2} align="center">
          <Text fontSize="xl">R {(paymentInfo.amount.units / 100).toFixed(2)}</Text>
          <Button size="lg" onClick={handleNewPayment}>
            New Payment
          </Button>
        </VStack>
      </>
    )
  }

  const handleNewPayment = () => {
    navigate('/payment')
  }

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={{ base: 'transparent', sm: 'bg.surface' }}
        boxShadow={{ base: 'none', sm: 'md' }}
        borderRadius={{ base: 'none', sm: 'xl' }}>
        {getResultBody()}
      </Box>
    </Container>
  )
}
