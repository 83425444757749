import React, { useEffect, useState } from 'react'

import { Container } from '@chakra-ui/layout'
import { ColorModeScript } from '@chakra-ui/react'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import {
  LoginScreen,
  ErrorPage,
  TerminalListScreen,
  PaymentScreen,
  LoadingScreen,
  PaymentResultScreen,
  PaymentProcessingScreen,
} from 'screens'

import { ory } from 'lib/ory'
import { getLinkStatus, getOnboardingStatus } from 'lib/client'

import { Session } from '@ory/client'

export const AppRouter: React.FC = () => {
  const [session, setSession] = useState<Session | undefined>()
  const [onboardingStatus, setOnboardingStatus] = useState('')

  useEffect(() => {
    ory
      .toSession()
      .then(({ data }) => {
        setSession(data)
      })
      .catch((err) => {
        console.error(err)
        window.location.replace(`https://auth.yoco.dev/ui/login`)
      })
  }, [])

  useEffect(() => {
    if (session) {
      getOnboardingStatus().then((data) => {
        console.log(`Onboarding Status: ${data.status}`)

        if (data.status === 'TERMINAL_LINKED') {
          getLinkStatus().then((data) => {
            console.log(`Linked Status: ${data.status}`)
            setOnboardingStatus(data.status)
          })
        } else {
          setOnboardingStatus(data.status)
        }
      })
    }
  }, [session])

  useEffect(() => {
    if (session) {
      const location = window.location.pathname
      console.log('onboarding status use effect: ', onboardingStatus, location)
      let newPath = '/login'

      switch (onboardingStatus) {
        case 'NEW':
          newPath = '/login'
          break

        case 'PENDING_CREATION':
        case 'DISCONNECTED':
        case 'YOCO_LINKED':
          newPath = '/terminals'
          break

        case 'CREATED':
        case 'TERMINAL_LINKED':
          newPath = '/payment'
          break

        default:
          return
      }

      if (newPath !== location) {
        window.location.href = newPath
      }
    }
  }, [session, onboardingStatus])

  if (!session) {
    return <LoadingScreen />
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <LoadingScreen />,
    },
    {
      path: '/login',
      element: <LoginScreen />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/payment',
      element: <PaymentScreen />,
    },
    {
      path: '/payment/processing/:uuid',
      element: <PaymentProcessingScreen />,
    },
    {
      path: '/payment/result/:uuid',
      element: <PaymentResultScreen />,
    },
    {
      path: '/terminals',
      element: <TerminalListScreen />,
    },
  ])

  return (
    <Container>
      <ColorModeScript />
      <RouterProvider router={router} />
    </Container>
  )
}
