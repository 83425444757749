import React, { useState } from 'react'

import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { Container, Box, Text, Button, VStack, HStack, Input, WrapItem } from '@chakra-ui/react'

import { Loading } from 'components'
import { Amount, PaymentRequest } from 'types'
import { startPaymentFlow } from 'lib/client'

export const PaymentScreen: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [billAmount, setBillAmount] = useState<number>(0)
  const [customAmount, setCustomAmount] = useState<string>('')
  const navigate = useNavigate()

  const getBody = () => {
    if (loading) {
      return <Loading title={'Requesting'} />
    }

    return (
      <>
        <VStack spacing={1}>
          <Text fontWeight={'bolder'} fontSize="xl">
            Bill Amount:
          </Text>
          <Text fontSize="xl">R{(billAmount / 100).toFixed(2)}</Text>
        </VStack>
        <VStack spacing={2}>
          <Input type="number" value={customAmount} onChange={(e) => setCustomAmount(e.target.value)} />
          <WrapItem>
            <Button padding={'10px'} onClick={handleAddCustomAmount}>
              Add Custom Amount
            </Button>
          </WrapItem>
        </VStack>
        <HStack spacing={4} mb={{ base: '6', md: '8' }}>
          <Button onClick={() => handleAddAmount(500)}>Add R5</Button>
          <Button onClick={() => handleAddAmount(1000)}>Add R10</Button>
          <Button onClick={() => handleAddAmount(2000)}>Add R20</Button>
        </HStack>
        <Button colorScheme={'blue'} onClick={handlePayment}>
          Start Payment Flow
        </Button>
      </>
    )
  }

  const handleAddAmount = (amount: number) => {
    setBillAmount((prevAmount) => prevAmount + amount)
  }

  const handleAddCustomAmount = () => {
    const amount = parseFloat(customAmount)

    if (!isNaN(amount) && amount > 0) {
      setBillAmount((prevAmount) => prevAmount + amount)
      setCustomAmount('')
    } else {
      alert('Please enter a valid positive number.')
    }
  }

  const handlePayment = () => {
    if (billAmount < 200) {
      alert('Please enter an amount greater than R2')
      return
    }

    const amount: Amount = {
      currencyCode: 'ZAR',
      units: billAmount,
    }

    const request: PaymentRequest = {
      request_uuid: uuidv4(),
      amount: amount,
    }

    try {
      setLoading(true)
      startPaymentFlow(request).then(() => navigate('/payment/processing/' + request.request_uuid))
    } catch (e) {
      console.log(`Payment request exception: ${e}`)
      navigate('/payment/result/' + request.request_uuid)
    }

    setBillAmount(0)
  }

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={{ base: 'transparent', sm: 'bg.surface' }}
        boxShadow={{ base: 'none', sm: 'md' }}
        borderRadius={{ base: 'none', sm: 'xl' }}>
        <VStack spacing={8}>{getBody()}</VStack>
      </Box>
    </Container>
  )
}
