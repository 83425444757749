export type Amount = {
  currencyCode: string
  units: number
}

export type LinkedStatus = {
  status: 'ACTIVE' | 'PENDING_CREATION' | 'DISCONNECTED'
}

export interface Business {
  uuid: string
  displayName: string
}

export type PaymentRequest = {
  request_uuid: string
  amount: Amount
}

export interface Terminal {
  uuid: string
  status: TerminalStatus
  model: TerminalModel
  serialNumber: string
  business?: Business
}

export enum TerminalStatus {
  STATUS_UNSPECIFIED = 0,
  ACTIVE = 1,
}

export interface ListTerminalsResponse {
  terminals: Terminal[]
}

export enum TerminalModel {
  NEO_TOUCH = 'NEO_TOUCH',
  KHUMO = 'KHUMO',
  KHUMO_PRINT = 'KHUMO_PRINT',
}

export type Customer = {
  id: number
  identityId: string
  displayName: string
  posDeviceUuid: string
  yocoApiToken: string
  yocoBusinessUuid: string
  yocoBusinessName: string
  onoMerchantId: string
  posTerminalLinkUuid?: string
  linkedTerminalUuid?: string
  createdAt: number
}

export type OnboardingStatus = {
  status: 'NEW' | 'YOCO_LINKED' | 'TERMINAL_LINKED'
  customer?: Customer
}

export type PaymentInfo = {
  payment_uuid: string
  request_uuid: string
  status: string
  amount: Amount
  flow_state: string
  flow_status_message: string
  started: string
  last_updated: string
  terminal: {
    uuid: string
    model: TerminalModel
    serial_number: string
  }
  result?: PosPaymentResult
}

export interface PosPaymentResult {
  outcome: string
  finalAmount: Amount
  retrievalReferenceNumber: string
  merchantId: string
  terminalId: string
  authorizationId: string
  authorizationCode: string
  cardDetails: CardDetails
  emvApplicationInfo: EmvApplicationInfo
  paymentFlowStartTime: bigint
}

export enum PosPaymentStatus {
  PENDING = 'PENDING',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
}

export interface CardDetails {
  scheme: string
  maskedPan: string
  expiryYear: number
  expiryMonth: number
  panEntryMode: PanEntryMode
  cardholderVerificationMethod: CardholderVerificationMethod
}

export enum PanEntryMode {
  UNKNOWN = 'UNKNOWN',
  MANUAL = 'MANUAL',
  MAGNETIC_STRIPE = 'MAGNETIC_STRIPE',
  BAR_CODE = 'BAR_CODE',
  OCR = 'OCR',
  CHIP_WITH_CVV = 'CHIP_WITH_CVV',
  CONTACTLESS_ICC = 'CONTACTLESS_ICC',
  MAG_STRIPE_WITH_CVV = 'MAG_STRIPE_WITH_CVV',
  CONTACTLESS_MAG_STRIPE = 'CONTACTLESS_MAG_STRIPE',
  CHIP_WITHOUT_CVV = 'CHIP_WITHOUT_CVV',
}

export enum CardholderVerificationMethod {
  FAILED = 'FAILED',
  OFFLINE_PLAIN_TEXT_PIN = 'OFFLINE_PLAIN_TEXT_PIN',
  ONLINE_PIN = 'ONLINE_PIN',
  OFFLINE_PLAIN_TEXT_PIN_AND_SIGNATURE = 'OFFLINE_PLAIN_TEXT_PIN_AND_SIGNATURE',
  OFFLINE_ENCIPHERED_PIN = 'OFFLINE_ENCIPHERED_PIN',
  OFFLINE_ENCIPHERED_PIN_AND_SIGNATURE = 'OFFLINE_ENCIPHERED_PIN_AND_SIGNATURE',
  PAPER_BASED_SIGNATURE_ONLY = 'PAPER_BASED_SIGNATURE_ONLY',
  APPROVE_CVM_PROCESSING = 'APPROVE_CVM_PROCESSING',
  NO_CVM_PERFORMED = 'NO_CVM_PERFORMED',
}

export interface EmvApplicationInfo {
  applicationIdentifier: string
  applicationName: string
}

export interface PosTerminalLink {
  uuid: string
  status: TerminalLinkStatus
  statusMessage?: string
  posDevice: PosDevice
  terminal: Terminal
}

export enum TerminalLinkStatus {
  PENDING_CREATION = 'PENDING_CREATION',
  PENDING_DISCONNECT = 'PENDING_DISCONNECT',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  DISCONNECTED = 'DISCONNECTED',
}

export interface PosDevice {
  uuid: string
  displayName: string
  business: Business
}

export type LinkToYocoRequest = {
  device_name: string
  email: string
  password: string
}
