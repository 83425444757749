import React from 'react'

import { Box, Container, VStack } from '@chakra-ui/react'
import { Loading } from 'components'

export const LoadingScreen: React.FC = () => {
  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={{ base: 'transparent', sm: 'bg.surface' }}
        boxShadow={{ base: 'none', sm: 'md' }}
        borderRadius={{ base: 'none', sm: 'xl' }}>
        <VStack spacing={10} align="center">
          <Loading title={'Please wait'} />
        </VStack>
      </Box>
    </Container>
  )
}
