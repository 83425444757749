import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Button, Box, Input, Stack, Heading, FormControl, FormLabel, Container, VStack } from '@chakra-ui/react'

import { Logo, PasswordField, Loading } from 'components'
import { linkToYoco } from 'lib/client'

export const LoginScreen: React.FC = () => {
  const navigate = useNavigate()

  const [displayName, setDisplayName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState<boolean>(false)

  const getBody = () => {
    if (loading) {
      return (
        <VStack spacing={10} align="center">
          <Loading title={'Connecting to Yoco'} />
        </VStack>
      )
    }

    return (
      <Stack spacing="6">
        <Stack spacing="5">
          <FormControl>
            <FormLabel htmlFor="displayName">POS Name</FormLabel>
            <Input id="displayName" type="displayName" value={displayName} onChange={handleDisplayNameChange} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input id="email" type="email" value={username} onChange={handleUsernameChange} />
          </FormControl>
          <PasswordField value={password} onChange={handlePasswordChange} />
        </Stack>
        <Stack spacing="6">
          <Button colorScheme={'blue'} onClick={handleLogin}>
            Link Yoco Account
          </Button>
        </Stack>
      </Stack>
    )
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }

  const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handleLogin = async () => {
    try {
      setLoading(true)

      const customer = await linkToYoco({
        device_name: displayName,
        email: username,
        password: password,
      })

      console.log('Linked to Yoco: ', customer)
      navigate('/terminals')
    } catch (e) {
      console.log(`Error while logging in: ${e}`)
      setLoading(false)
    }
  }

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Logo mx="auto" w="24" mb={{ base: '8', md: '10' }} />
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            <Heading size={{ base: 'xs', md: 'sm' }}>Link your Yoco Account</Heading>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={{ base: 'transparent', sm: 'bg.surface' }}
          boxShadow={{ base: 'none', sm: 'md' }}
          borderRadius={{ base: 'none', sm: 'xl' }}>
          {getBody()}
        </Box>
      </Stack>
    </Container>
  )
}
