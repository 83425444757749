import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Box, Button, Container, FormLabel, Image, Radio, Spacer, Text, VStack } from '@chakra-ui/react'

import { Terminal, TerminalModel } from 'types'
import { getTerminals, linkToTerminal } from 'lib/client'
import { Loading } from 'components'

export const TerminalListScreen: React.FC = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)
  const [loaderText, setLoaderText] = useState<string>('Fetching your card machines')
  const [terminals, setTerminals] = useState<Terminal[]>([])
  const [selectedTerminal, setSelectedTerminal] = useState<Terminal>()

  useEffect(() => {
    setLoading(true)
    getTerminals().then((r) => {
      setTerminals(r.terminals)
      setLoading(false)
    })
  }, [])

  const connectToTerminal = () => {
    if (!selectedTerminal) {
      return
    }

    try {
      setLoaderText('Connecting')
      setLoading(true)
      linkToTerminal(selectedTerminal.uuid).then(() => navigate('/payment'))
    } catch (e) {
      console.log(`Error while linking terminal in: ${e}`)
      setLoading(false)
    }
  }

  const handleTerminalSelected = (itemUUID: string) => {
    const selectedTerminal = terminals.find((value) => {
      return value.uuid === itemUUID
    })

    setSelectedTerminal(selectedTerminal)
  }

  const handleButtonClick = async () => {
    connectToTerminal()
  }

  const mapCardMachineModel = (model: TerminalModel) => {
    let result = ''
    switch (model) {
      case TerminalModel.KHUMO_PRINT:
        result = 'Khumo Print'
        break

      case TerminalModel.NEO_TOUCH:
        result = 'Neo Touch'
        break

      case TerminalModel.KHUMO:
        result = 'Khumo'
        break

      default:
        result = 'Card Machine'
        break
    }

    return result
  }

  if (loading) {
    return (
      <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={{ base: 'transparent', sm: 'bg.surface' }}
          boxShadow={{ base: 'none', sm: 'md' }}
          borderRadius={{ base: 'none', sm: 'xl' }}>
          <VStack spacing={10} align="center">
            <Loading title={loaderText} />
          </VStack>
        </Box>
      </Container>
    )
  }

  return (
    <VStack spacing={4} display="flex" width="720px" height="219px" flexDirection="column" alignItems="center">
      <Spacer />
      <Image src="96px-icon-serial-number.png"></Image>
      <Text fontSize="xl" fontWeight="bold">
        Available card machines
      </Text>
      <Text fontSize="sm">Find your serial number on the back of your card machine</Text>
      <VStack spacing={2} display="flex" width="720px" height="219px" flexDirection="column" alignItems="center">
        <FormLabel>Your card machines</FormLabel>
        {terminals.map((item: Terminal) => (
          <Radio
            value={item.uuid}
            size="sm"
            isChecked={selectedTerminal?.uuid === item.uuid}
            onChange={() => handleTerminalSelected(item.uuid)}>
            {mapCardMachineModel(item.model)} {item.serialNumber}
          </Radio>
        ))}
      </VStack>
      <Button
        display="flex"
        height="60px"
        width="200px"
        padding="12px"
        justifyContent="center"
        alignItems="center"
        gap="12px"
        borderRadius="1000px"
        fontSize="15px"
        isDisabled={!selectedTerminal}
        backgroundColor="#00A9E0"
        _hover={{ backgroundColor: '#7dc2d2' }}
        onClick={handleButtonClick}
        color="white">
        Connect
      </Button>
    </VStack>
  )
}
