import axios from 'axios'

import {
  Customer,
  ListTerminalsResponse,
  OnboardingStatus,
  PaymentInfo,
  PaymentRequest,
  PosTerminalLink,
  LinkToYocoRequest,
} from 'types'

export const getOnboardingStatus = async (): Promise<OnboardingStatus> => {
  const response = await axios.get('/api/onboarding/status')

  if (response.status !== 200) {
    throw new Error('Failed to get onboarding status')
  }

  return response.data
}

export const linkToYoco = async (loginRequest: LinkToYocoRequest): Promise<Customer> => {
  const response = await axios.post('/api/yoco/link', loginRequest)

  if (response.status !== 200) {
    throw new Error('Failed to link to Yoco account')
  }

  return response.data
}

export const getLinkStatus = async (): Promise<PosTerminalLink> => {
  const response = await axios.get('/api/terminal/linked')

  if (response.status !== 200) {
    throw new Error('Failed to get linked terminal')
  }

  return response.data
}

export const getTerminals = async (): Promise<ListTerminalsResponse> => {
  const response = await axios.get('/api/yoco/terminals')

  if (response.status !== 200) {
    throw new Error('Failed to get terminals')
  }

  return response.data
}

export const linkToTerminal = async (terminalUuid: string): Promise<PosTerminalLink> => {
  const response = await axios.post(`/api/yoco/terminals/${terminalUuid}/link`)

  if (response.status !== 200) {
    throw new Error('Failed to link terminal')
  }

  return response.data
}

export const startPaymentFlow = async (request: PaymentRequest): Promise<PaymentInfo> => {
  const response = await axios.post('/api/payments', request)

  if (response.status !== 200) {
    throw new Error('Payment failed to start')
  }

  return response.data
}

export const fetchPaymentInfo = async (requestUuid: string): Promise<PaymentInfo> => {
  const response = await axios.get(`/api/payments/${requestUuid}`)

  if (response.status !== 200) {
    throw new Error('Payment not found')
  }

  return response.data
}
