import { Configuration, FrontendApi } from '@ory/client'

export const ory = new FrontendApi(
  new Configuration({
    basePath: 'https://auth.yoco.dev',
    baseOptions: {
      withCredentials: true,
    },
  }),
)
