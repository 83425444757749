import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    black: '#222222',
    white: '#ffffff',
    brand: {
      orange: '#EE4200',
      green: '#014737',
      yellow: '#FF9E00',
      blue: '#00A9E0',
    },
    grey: {
      100: '#F8F9FB',
      150: '#F3F5F8',
      200: '#E7E9ED',
      300: '#CED4DE',
      400: '#C9CED8',
      500: '#C3C8D1',
      600: '#A5AAB1',
      700: '#878B90',
      800: '#6A6D71',
      900: '#4C4E52',
    },
    green: {
      50: '#E6F4F1',
      100: '#CCEAE3',
      200: '#9AD5C7',
      300: '#67BFAA',
      400: '#34AA8E',
      500: '#019472',
      600: '#018D6D',
      700: '#018567',
      800: '#016F56',
      900: '#015944',
    },
    blue: {
      100: '#CCEEF9',
      200: '#99DDF3',
      300: '#66CBEC',
      400: '#33BAE6',
      500: '#00A9E0',
      600: '#0098CA',
      700: '#0087B3',
      800: '#006E92',
      900: '#005570',
    },
    yellow: {
      100: '#FFEED1',
      200: '#FFDDA4',
      300: '#FFCB76',
      400: '#FFBA48',
      500: '#FFA81A',
      600: '#F3A019',
      700: '#E69717',
      800: '#D98F16',
      900: '#CC8615',
    },
    red: {
      100: '#FDCED0',
      200: '#FC9EA1',
      300: '#FA6D71',
      400: '#F93D42',
      500: '#F70C13',
      600: '#BA090F',
      700: '#7C060A',
      800: '#630508',
      900: '#4A0406',
    },
    github: {
      500: '#1a1414',
    },
    gitlab: {
      500: '#FC6D26',
    },
  },
})
