import React from 'react'
import { chakra, HTMLChakraProps, useToken } from '@chakra-ui/react'

export const Logo: React.FC<HTMLChakraProps<'svg'>> = (props) => {
  const [white, brandBlue] = useToken('colors', ['white', 'brand.blue'])

  return (
    <chakra.svg aria-hidden viewBox="0 0 219 96" fill="none" {...props}>
      <path
        d="M170.335 0H47.9749C21.4791 0 0 21.4791 0 47.9749C0 74.4706 21.4791 95.9497 47.9749 95.9497H170.335C196.83 95.9497 218.309 74.4706 218.309 47.9749C218.309 21.4791 196.83 0 170.335 0Z"
        fill={brandBlue}
      />
      <path
        d="M44.1654 55.0056L29.5911 31.0592H43.5318L50.4302 43.2402H50.8659L57.8145 31.0492H71.4637L56.8894 55.0056V65.0615H44.152L44.1654 55.0056Z"
        fill={white}
      />
      <path
        d="M67.7732 48.0603C67.7732 36.6939 75.543 29.7955 87.8849 29.7955C100.227 29.7955 107.997 36.6939 107.997 48.0603C107.997 59.4268 100.223 66.3218 87.8849 66.3218C75.5464 66.3218 67.7732 59.4268 67.7732 48.0603ZM95.5944 48.0603C95.5944 43.0559 92.6782 40.1899 87.8849 40.1899C83.0916 40.1899 80.2089 43.0559 80.2089 48.0603C80.2089 53.0648 83.0749 55.9274 87.8849 55.9274C92.695 55.9274 95.6045 53.0615 95.6045 48.0603H95.5944Z"
        fill={white}
      />
      <path
        d="M110.031 48.1576C110.031 36.7911 118.143 30.0872 130.143 30.0872C136.022 30.0872 140.149 31.495 143.403 33.3419V44.8056H143.306C139.324 42.124 135.875 40.5788 131.601 40.5788C126.453 40.5788 122.762 43.2603 122.762 48.0603C122.762 53.0145 126.55 55.4916 131.551 55.4916C135.972 55.4916 139.518 53.9866 143.306 51.2179H143.403V62.8257C140.722 64.381 136.029 66.0302 130.046 66.0302C117.654 66.0335 110.031 59.524 110.031 48.1576Z"
        fill={white}
      />
      <path
        d="M145.539 48.0603C145.539 36.6939 153.308 29.7955 165.65 29.7955C177.992 29.7955 185.762 36.6939 185.762 48.0603C185.762 59.4268 177.992 66.3218 165.65 66.3218C153.308 66.3218 145.539 59.4268 145.539 48.0603ZM173.36 48.0603C173.36 43.0559 170.447 40.1899 165.65 40.1899C160.854 40.1899 157.978 43.0559 157.978 48.0603C157.978 53.0648 160.844 55.9274 165.65 55.9274C170.457 55.9274 173.37 53.0615 173.37 48.0603H173.36Z"
        fill={white}
      />
    </chakra.svg>
  )
}
