import React, { useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Container, Box, Text, VStack, Spacer } from '@chakra-ui/react'

import { Loading, Logo } from 'components'
import { PaymentInfo } from 'types'
import { useRafPolling } from 'hooks'
import { fetchPaymentInfo } from 'lib/client'

export const PaymentProcessingScreen: React.FC = () => {
  const { uuid } = useParams()
  const navigate = useNavigate()
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>()

  if (!uuid) {
    throw new Error('Missing uuid')
  }

  useRafPolling(async () => {
    const result = await fetchPaymentInfo(uuid)

    if (result.status !== 'PENDING') {
      navigate('/payment/result/' + uuid)
    }

    setPaymentInfo(result)
  }, 500)

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={{ base: 'transparent', sm: 'bg.surface' }}
        boxShadow={{ base: 'none', sm: 'md' }}
        borderRadius={{ base: 'none', sm: 'xl' }}>
        <VStack spacing={10} align="center">
          <Loading title={'Processing payment'} />
        </VStack>
        {paymentInfo && (
          <VStack spacing={8} align="center">
            <Spacer />
            <Text fontSize="xl">R {(paymentInfo.amount.units / 100).toFixed(2)}</Text>
            <Text align={'center'}>{paymentInfo.flow_status_message}</Text>
            <Logo m="10px" w="24" />
          </VStack>
        )}
      </Box>
    </Container>
  )
}
