import React from 'react'
import { Spinner, Text } from '@chakra-ui/react'

export type LoadingProps = {
  title: string
}

export const Loading: React.FC<LoadingProps> = ({ title }: LoadingProps) => {
  return (
    <>
      <Text fontSize={24} fontWeight={'bolder'}>
        {title}
      </Text>
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
    </>
  )
}
